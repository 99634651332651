<template>
	<el-form ref="form" :model="form" label-width="220px" class="form" :rules="rules"  :validate-on-rule-change="false">
		<div class="cardBox">
			<div class="card">
				<el-form-item label="注册人身份证人像面" prop="cphoto">
					<el-upload class="avatar-uploader" :action="picUrl"
						v-loading.fullscreen.lock="loadingA"
						element-loading-text="拼命加载中……"
						:on-progress="changeA"
						element-loading-background="rgba(250, 250, 250, 0.5)"
						:before-upload="beforeAvatarUploadA"
						:show-file-list="false" :data="fileInfo" :on-success="handleCardA" accept="image/jpeg,image/png">
						
						<img v-if="imageUrlA" :src="imageUrlA" :onerror="imgSrc" class="avatar">
						
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="tishinews">
						小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					</div>
				</el-form-item>
				<el-form-item label="注册人身份证国徽面" prop="cphoto_back">
					
					<el-upload class="avatar-uploader" :action="picUrl"
					
						v-loading.fullscreen.lock="loadingB"
						
						  element-loading-text="拼命加载中……"
						  
						 :on-progress="changeB"
						 
						 :before-upload="beforeAvatarUploadB"
						 
						 element-loading-background="rgba(250, 250, 250, 0.5)"
						 
						:show-file-list="false" :data="fileInfo" :on-success="handleCardB" accept="image/jpeg,image/png">
						
						<img v-if="imageUrlB" :src="imageUrlB" :onerror="imgSrc" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						
					</el-upload>
					<div class="tishinews">
						小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					</div>
				</el-form-item>
			</div>
			<!-- <div class="red">注：请上传法人身份证照片</div> -->
		</div>
		<el-form-item label="注册人" prop="cperson" class="name">
			<el-input v-model="form.cperson" />
		</el-form-item>
		<el-form-item label="注册人身份证号" prop="cpcode">
			<el-input v-model="form.cpcode" />
		</el-form-item>
		<el-form-item label="联系电话" prop="cphone">
			<el-input v-model="form.cphone" />
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">下一步</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		data() {
			return {
				Frperson:"",
				imgSrc:'this.src="' + require('../../assets/img/mr.jpg') + '"',
				loadingA:false,
				loadingB:false,
				peoname:"",
				imageUrlA:"",
				imageUrlB:"",
				form: {
					cpcode:'',
					cperson: '',
					cphone: '',
					cphoto:'',
					cphoto_back:''
				},
				fileInfo:{
					type:"Member",
					zhengjian:"1"//身份证
				},
				imageUrl: '',
				rules: {
					cphoto: [{
						required: true,
						message:'请上传身份证人像面'
					}],
					cphoto_back: [{
						required: true,
						
						message:'请上传身份证国徽面'
					}],
					cpcode:[{
						required: true,
						message: '请输入注册人身份证号'
					}],
					cperson: [{
						required: true,
						message: '请输入注册人'
					}],
					cphone: [{
						required: true,
						message: '请输入联系电话'
					}],
					
				},
				picUrl:''
			}
		},
		mounted(){
			
		},
		created() {
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
			if(this.$route.query.queryForm.cphoto || this.$route.query.queryForm.cphoto_back){
				this.form = this.$route.query.queryForm
				this.imageUrlA = this.$route.query.queryForm.cphoto ? this.$store.state.IMG_URL+'/'+this.$route.query.queryForm.cphoto :''
				this.imageUrlB = this.$route.query.queryForm.cphoto_back ? this.$store.state.IMG_URL+'/'+this.$route.query.queryForm.cphoto_back :''
			}
		},
		methods: {
			changeA(event, file, fileList){
				this.loadingA = true;
			},
			handleCardA(res, file) {
				// this.handleAvatar(res, file,2)
				if (res.code == 200) {
				   this.loadingA = false;
				   this.form.cperson= res.data.data.姓名
				   this.form.cpcode=  res.data.data.公民身份号码
				   this.form.cphoto = res.data.src
				   this.imageUrlA = URL.createObjectURL(file.raw);
				   let peocode = res.data.data.公民身份号码
				   this.peoname = res.data.data.姓名
				   let peoadress = res.data.data.住址
				   if (!peocode || !this.peoname || !peoadress) {
						this.$message.error("图片不正确/不清晰,请重新上传~")
				   } else {
						this.$message.success("上传成功")
				   }
				}else{
					this.loadingA = false;
					this.$message.error(res.msg)
				}
				
			},
			beforeAvatarUploadA(file){
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			changeB(event, file, fileList){
				this.loadingB = true;
			},
			handleCardB(res, file) {
				// this.handleAvatar(res, file,3)
				if (res.code == 200) {
					this.loadingB = false;
				   this.form.cphoto_back = res.data.src
				   this.imageUrlB = URL.createObjectURL(file.raw);
				   this.$message.success("上传成功")
				  }else{
					  this.loadingB = false;
					  this.$message.error(res.msg)
				  }
			},
			beforeAvatarUploadB(file){
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			onSubmit(data) {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$emit('eventSubmit', this.form)
					} else{
						this.$message.error("请仔细核对信息并上传与注册人姓名一致的身份证照片~")
						return false;
					}
				});
			}
		},
	}
</script>

<style>
	.card{
		display: flex;
		justify-content: space-around;
	}
	.red{
		color: red;
		margin-left: 150px;
		height: 50px;
		line-height: 30px;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
